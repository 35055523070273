import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, Alert, Card, CardContent, Grid, Typography, Avatar, Paper, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import moment from 'moment';
import { useAuth } from './auth';
import UserLocationMap from './UserLocationMap';
import UserEngagementCard from './UserEngagementCard';

const defaultUserIcon = 'https://cdn-icons-png.flaticon.com/512/847/847969.png';

const UserDetails = () => {
  const { token } = useAuth();
  const { userId } = useParams();
  const navigate = useNavigate(); // For navigation (back or to dashboard)
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // Toggle edit mode
  const [formData, setFormData] = useState({}); // Store form data
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;
  const [blockDialogOpen, setBlockDialogOpen] = useState(false);
  const [blockReason, setBlockReason] = useState('');
  const [blockExpiresAt, setBlockExpiresAt] = useState('');
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockHistory, setBlockHistory] = useState([]);
  const [activeBlock, setActiveBlock] = useState(null);
  const [blockDurationType, setBlockDurationType] = useState('temporary'); // 'temporary', 'permanent', 'custom'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
        setFormData(response.data);
        await checkBlockStatus();
        const history = await fetchBlockHistory();
        setBlockHistory(history);
      } catch (err) {
        setError('User not found');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, token, apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      await axios.put(`${apiUrl}/api/users/admin/${userId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(formData); // Update the state with the modified data
      setIsEditing(false); // Exit edit mode
    } catch (err) {
      setError('Error saving user data');
    }
  };

  const formatDate = (date) => {
    return date ? moment(date).format('DD/MM/YYYY') : 'N/A';
  };

  const formatBalance = (balance) => {
    return balance !== undefined
      ? balance.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : 'N/A';
  };

  const handleBlockUser = async () => {
    try {
      let expiresAtValue;
      if (blockDurationType === 'permanent') {
        expiresAtValue = null;
      } else if (blockDurationType === 'temporary') {
        // Par défaut 24h
        expiresAtValue = moment().add(24, 'hours').format('YYYY-MM-DDTHH:mm');
      } else {
        expiresAtValue = blockExpiresAt;
      }

      await axios.put(
        `${apiUrl}/api/users/${userId}/block`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            reason: blockReason,
            expiresAt: expiresAtValue
          }
        }
      );
      await checkBlockStatus();
      const history = await fetchBlockHistory();
      setBlockHistory(history);
      setBlockDialogOpen(false);
      setBlockReason('');
      setBlockExpiresAt('');
      setBlockDurationType('temporary');
    } catch (err) {
      setError('Error blocking user');
    }
  };

  const handleUnblockUser = async () => {
    try {
      await axios.put(
        `${apiUrl}/api/users/${userId}/unblock`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await checkBlockStatus();
      const history = await fetchBlockHistory();
      setBlockHistory(history);
    } catch (err) {
      setError('Error unblocking user');
    }
  };

  const fetchBlockHistory = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/users/${userId}/block-history`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      setError('Error fetching block history');
      return [];
    }
  };

  const checkBlockStatus = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/users/${userId}/block-status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.blocked === false) {
        setIsBlocked(false);
        setActiveBlock(null);
      } else {
        setIsBlocked(true);
        setActiveBlock(response.data);
      }
    } catch (err) {
      setError('Error checking block status');
    }
  };

  const handleOpenBlockDialog = async () => {
    try {
      const history = await fetchBlockHistory();
      setBlockHistory(history);
      setBlockDialogOpen(true);
    } catch (err) {
      setError('Error loading block history');
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  const userLocation = user?.locations?.length > 0 ? user.locations[0] : null;

  const handleQuit = () => {
    if (isEditing) {
      setIsEditing(false); // Exit edit mode without saving
    } else {
      navigate(-1); // Go back to the previous page
    }
  };

  return (
    <div style={{ padding: '70px' }}>
      <Paper elevation={0} style={{ padding: '20px', borderRadius: '30' }}>
        <Grid container spacing={2} justifyContent="center">
          {/* Profile picture + Account info */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent style={{ textAlign: 'center' }}>
                <Avatar
                  alt={user?.name}
                  src={user?.profilePicture ? `${apiUrl}/uploads/${user.profilePicture}` : defaultUserIcon}
                  sx={{ width: 100, height: 100, margin: '0 auto' }}
                />
                {isEditing ? (
                  <>
                    <TextField
                      name="name"
                      label="First Name"
                      value={formData.name}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      name="lastName"
                      label="Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </>
                ) : (
                  <>
                    <Typography variant="h5" component="div" gutterBottom>
                      {user?.name} {user?.lastName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {user?.email}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {user?.city}, {user?.country}
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Account Information
                </Typography>
                <Grid container spacing={2}>
                  {isEditing ? (
                    <>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="email"
                          label="Email"
                          value={formData.email}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                          type="email"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="phone"
                          label="Phone"
                          value={formData.phone || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="dateOfBirth"
                          label="Date of Birth"
                          type="date"
                          value={formData.dateOfBirth || ''}
                          onChange={handleChange}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="balance"
                          label="Balance"
                          type="number"
                          inputProps={{ step: '0.01', min: '0' }}
                          value={formData.balance || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="iban"
                          label="IBAN"
                          value={formData.iban || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="paypalEmail"
                          label="PayPal Email"
                          value={formData.paypalEmail || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="city"
                          label="City"
                          value={formData.city || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="country"
                          label="Country"
                          value={formData.country || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="zipCode"
                          label="Zip Code"
                          value={formData.zipCode || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="address"
                          label="Address"
                          value={formData.address || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Email:</strong> {user?.email || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Phone:</strong> {user?.phone || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Date of Birth:</strong> {formatDate(user?.dateOfBirth)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Balance:</strong> {formatBalance(user?.balance)} CHF
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>IBAN:</strong> {user?.iban || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>PayPal Email:</strong> {user?.paypalEmail || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>City:</strong> {user?.city || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Country:</strong> {user?.country || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Zip Code:</strong> {user?.zipCode || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Address:</strong> {user?.address || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Created At:</strong> {formatDate(user?.createDate)}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* User engagement and location */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Engagement
                </Typography>
                <UserEngagementCard userId={userId} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Location
                </Typography>
                <UserLocationMap user={user} userLocation={userLocation} apiUrl={apiUrl} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Block History
                </Typography>
                {activeBlock && (
                  <Paper 
                    elevation={3} 
                    style={{ 
                      padding: '15px', 
                      marginBottom: '20px', 
                      backgroundColor: '#ffebee' 
                    }}
                  >
                    <Typography variant="h6" color="error" gutterBottom>
                      Active Block
                    </Typography>
                    <Typography variant="body1">
                      <strong>Reason:</strong> {activeBlock.reason}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Created:</strong> {moment(activeBlock.createdAt).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Expires:</strong> {activeBlock.permanent ? 'Never' : moment(activeBlock.expiresAt).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                  </Paper>
                )}
                
                {blockHistory.length > 0 ? (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell><strong>Date</strong></TableCell>
                          <TableCell><strong>Reason</strong></TableCell>
                          <TableCell><strong>Duration</strong></TableCell>
                          <TableCell><strong>Status</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {blockHistory.map((block) => (
                          <TableRow 
                            key={block.id}
                            sx={{ 
                              backgroundColor: block.active ? '#fff3e0' : 'inherit'
                            }}
                          >
                            <TableCell>
                              {moment(block.createdAt).format('DD/MM/YYYY HH:mm')}
                            </TableCell>
                            <TableCell>{block.reason}</TableCell>
                            <TableCell>
                              {block.permanent ? 'Permanent' : 
                                block.expiresAt ? 
                                  `Until ${moment(block.expiresAt).format('DD/MM/YYYY HH:mm')}` : 
                                  'N/A'
                              }
                            </TableCell>
                            <TableCell>
                              <Typography
                                component="span"
                                sx={{
                                  color: block.active ? 'error.main' : 'text.secondary',
                                  fontWeight: block.active ? 'bold' : 'normal'
                                }}
                              >
                                {block.active ? 'Active' : 'Inactive'}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    No block history found
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {isEditing ? (
          <>
            <Button variant="contained" color="primary" onClick={handleSave} style={{ marginTop: '20px' }}>
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleQuit} style={{ marginLeft: '10px', marginTop: '20px' }}>
              Cancel
            </Button>
          </>
        ) : (
          <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)} style={{ marginTop: '20px' }}>
            Edit
          </Button>
        )}
        <Button variant="outlined" color="secondary" onClick={handleQuit} style={{ marginLeft: '10px', marginTop: '20px' }}>
          Quit
        </Button>
        <Button
          variant="contained"
          color={isBlocked ? "warning" : "error"}
          onClick={isBlocked ? handleUnblockUser : handleOpenBlockDialog}
          style={{ marginLeft: '10px', marginTop: '20px' }}
        >
          {isBlocked ? 'Unblock User' : 'Block User'}
        </Button>
      </Paper>

      <Dialog 
        open={blockDialogOpen} 
        onClose={() => setBlockDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Block User</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                label="Reason"
                type="text"
                fullWidth
                value={blockReason}
                onChange={(e) => setBlockReason(e.target.value)}
                required
              />
            </Grid>
            
            <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Block Duration</InputLabel>
                <Select
                  value={blockDurationType}
                  onChange={(e) => setBlockDurationType(e.target.value)}
                  label="Block Duration"
                >
                  <MenuItem value="temporary">24 hours</MenuItem>
                  <MenuItem value="permanent">Permanent</MenuItem>
                  <MenuItem value="custom">Custom duration</MenuItem>
                </Select>
              </FormControl>
              
              {blockDurationType === 'custom' && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      label="Date"
                      type="date"
                      fullWidth
                      value={blockExpiresAt.split('T')[0] || ''}
                      onChange={(e) => {
                        const date = e.target.value;
                        const time = blockExpiresAt.split('T')[1] || '23:59';
                        setBlockExpiresAt(`${date}T${time}`);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      label="Time"
                      type="time"
                      fullWidth
                      value={blockExpiresAt.split('T')[1] || ''}
                      onChange={(e) => {
                        const date = blockExpiresAt.split('T')[0] || moment().format('YYYY-MM-DD');
                        setBlockExpiresAt(`${date}T${e.target.value}`);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            {activeBlock && (
              <Grid item xs={12}>
                <Typography variant="h6" color="error" gutterBottom>
                  Current Block Status
                </Typography>
                <Typography variant="body1">
                  <strong>Reason:</strong> {activeBlock.reason}
                </Typography>
                <Typography variant="body1">
                  <strong>Created:</strong> {moment(activeBlock.createdAt).format('DD/MM/YYYY HH:mm')}
                </Typography>
                <Typography variant="body1">
                  <strong>Expires:</strong> {activeBlock.permanent ? 'Never' : moment(activeBlock.expiresAt).format('DD/MM/YYYY HH:mm')}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                Block History
              </Typography>
              {blockHistory.length > 0 ? (
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Date</strong></TableCell>
                        <TableCell><strong>Reason</strong></TableCell>
                        <TableCell><strong>Duration</strong></TableCell>
                        <TableCell><strong>Status</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {blockHistory.map((block) => (
                        <TableRow 
                          key={block.id}
                          sx={{ 
                            backgroundColor: block.active ? '#fff3e0' : 'inherit'
                          }}
                        >
                          <TableCell>
                            {moment(block.createdAt).format('DD/MM/YYYY HH:mm')}
                          </TableCell>
                          <TableCell>{block.reason}</TableCell>
                          <TableCell>
                            {block.permanent ? 'Permanent' : 
                              block.expiresAt ? 
                                `Until ${moment(block.expiresAt).format('DD/MM/YYYY HH:mm')}` : 
                                'N/A'
                          }
                          </TableCell>
                          <TableCell>
                            <Typography
                              component="span"
                              sx={{
                                color: block.active ? 'error.main' : 'text.secondary',
                                fontWeight: block.active ? 'bold' : 'normal'
                              }}
                            >
                              {block.active ? 'Active' : 'Inactive'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No block history found
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBlockDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleBlockUser} 
            disabled={!blockReason}
            color="error"
            variant="contained"
          >
            Block
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDetails;
